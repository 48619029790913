<template>
	<modal class="NoxModalEvents" name="NoxModalEvents" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_open_loading" v-if="noxIsOpenLoading"><div class="nox_loading"></div></div>
			<div class="nox_modal_open" v-else>
				<div class="nox_modal_head">
					<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="nox_modal_title">
						<span v-if="noxType == 'addEvent'">Добавление мероприятия</span>
						<span v-else-if="noxType == 'editEvent'">Редактирование мероприятия</span>
						<span v-else-if="noxType == 'deleteEvent'">Удаление мероприятия</span>
						<span v-else-if="noxType == 'buyTickets'" v-html="$store.getters.getLanguageText('5.11', 0)"></span>
						<span v-else-if="noxType == 'getTickets' && noxSType == 'admin'" v-html="$store.getters.getLanguageText('5.11', 6)"></span>
						<span v-else-if="noxType == 'getTickets' && noxSType == 'owner'" v-html="$store.getters.getLanguageText('5.11', 6)"></span>
						<span v-else-if="noxType == 'getTickets' && noxSType == 'team'" v-html="$store.getters.getLanguageText('5.11', 15)"></span>
						<span v-else-if="noxType == 'getTickets' && noxSType == 'user'" v-html="$store.getters.getLanguageText('5.11', 5)"></span>
						<span v-else-if="noxType == 'deleteTicket'" v-html="$store.getters.getLanguageText('5.11', 11)"></span>
						<span v-else-if="noxType == 'deleteTickets'">Удаление всех билетов мероприятия</span>
					</div>
				</div>
				<div class="nox_modal_body">
					<div class="nox_modal_info">
						<div class="nox_modal_info_events">
							<div v-if="['addEvent', 'editEvent'].includes(noxType)">
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Статус мероприятия:</div>
									<div class="nox_modal_info_row_value">
										<toggle-button class="nox_toggle_button" :value="toggleValue(noxStatus)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange($event, 1)"></toggle-button>
										<div v-html="noxAlertStatus"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">NOX ID организатора:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxUserId" placeholder="NOX ID организатора" autocomplete="off">
										<div v-html="noxAlertUserId"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Название мероприятия (RU):</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxNameRU" placeholder="название мероприятия (ru)" autocomplete="off">
										<div v-html="noxAlertNameRU"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Название мероприятия (EN):</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxNameEN" placeholder="название мероприятия (en)" autocomplete="off">
										<div v-html="noxAlertNameEN"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Текст мероприятия (RU):</div>
									<div class="nox_modal_info_row_value">
										<textarea class="nox_textarea small" v-model.trim="noxTextRU" placeholder="html текст мероприятия (ru)"></textarea>
										<div v-html="noxAlertTextRU"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Текст мероприятия (EN):</div>
									<div class="nox_modal_info_row_value">
										<textarea class="nox_textarea small" v-model.trim="noxTextEN" placeholder="html текст мероприятия (en)"></textarea>
										<div v-html="noxAlertTextEN"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Дата проведения:</div>
									<div class="nox_modal_info_row_value">
										<datetime type="datetime" class="nox_datetime" format="dd.MM.yyyy, HH:mm:ss" :phrases="{ ok: '[Продолжить]', cancel: '[Закрыть]' }" v-model="noxDatetime" placeholder="дата проведения" auto></datetime>
										<div v-html="noxAlertDatetime"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Место проведения (RU):</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxLocationRU" placeholder="место проведения (ru)" autocomplete="off">
										<div v-html="noxAlertLocationRU"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Место проведения (EN):</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxLocationEN" placeholder="место проведения (en)" autocomplete="off">
										<div v-html="noxAlertLocationEN"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Цвет подложки (HEX):</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxBGColor" placeholder="цвет подложки (пример: #883388)" autocomplete="off">
										<div v-html="noxAlertBGColor"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Пароль билета:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxTicketPassword" placeholder="пароль билета" autocomplete="off">
										<div v-html="noxAlertTicketPassword"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Количество билетов:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.number="noxCountTickets" placeholder="количество билетов" autocomplete="off">
										<div v-html="noxAlertCountTickets"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Стоимость билета:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.number="noxTicketCost" placeholder="стоимость билета" autocomplete="off">
										<div v-html="noxAlertTicketCost"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Текст билета (RU):</div>
									<div class="nox_modal_info_row_value">
										<textarea class="nox_textarea small" v-model.trim="noxTicketTextRU" placeholder="текст билета (ru)"></textarea>
										<div v-html="noxAlertTicketTextRU"></div>
									</div>
								</div>
								<div class="nox_modal_info_row last">
									<div class="nox_modal_info_row_label">Текст билета (EN):</div>
									<div class="nox_modal_info_row_value">
										<textarea class="nox_textarea small" v-model.trim="noxTicketTextEN" placeholder="текст билета (en)"></textarea>
										<div v-html="noxAlertTicketTextEN"></div>
									</div>
								</div>
							</div>
							<div v-else-if="noxType == 'deleteEvent'">Вы действительно хотите удалить мероприятие?</div>
							<div v-else-if="noxType == 'buyTickets'">
								<div class="center" v-if="noxCountBuyTickets >= noxCountTickets" v-html="$store.getters.getLanguageText('5.11', 13)"></div>
								<div v-else-if="noxStep == 1">
									<p><span v-html="$store.getters.getLanguageText('5.16', 7, { balance: $parent.$parent.$parent.getNumberFormatForDollar(noxBalance1, 2) })"></span><br><span v-html="$store.getters.getLanguageText('5.16', 8, { balance: $parent.$parent.$parent.getNumberFormatForDollar(noxBalance3, 2) })"></span><br><span v-html="$store.getters.getLanguageText('5.11', 1, { cost: parseFloat(noxTicketCost).toFixed(2) })"></span></p>
									<div class="nox_modal_info_row last">
										<input type="text" class="nox_input" v-model.number="noxTicketCount" :placeholder="$store.getters.getLanguageText('5.11', 3)" @input="changeTicketCount" autocomplete="off">
										<div v-html="noxAlertTicketCount"></div>
									</div>
									<p class="margin_top_15"><span v-html="$store.getters.getLanguageText('5.11', 2, { total: parseFloat(noxTotalCost).toFixed(2) })"></span><span v-if="!noxIsBuy"><br><span class="red" v-html="$store.getters.getLanguageText('5.16', 12, { amount: parseFloat(noxTotalCost - noxBalance).toFixed(2) })"></span></span></p>
								</div>
								<div v-else-if="noxStep == 2" v-html="$store.getters.getLanguageText('5.11', 4, { options: [$parent.$parent.$parent.getDeclension(noxStartCount)], count: noxStartCount, amount: parseFloat(noxTotalCost).toFixed(2) })"></div>
							</div>
							<div v-else-if="noxType == 'getTickets'">
								<div class="nox_modal_info_tickets" v-if="noxTicketsData.length">
									<div class="nox_modal_info_tickets_team" v-if="noxSType == 'team'">
										<div class="nox_modal_info_tickets_team_item" v-for="(value, index) in noxTicketsData" :key="index">
											<div class="nox_modal_info_tickets_team_item_avatar"><img :src="$parent.$parent.$parent.getAvatarByLink(value.avatar)"></div>
											<div class="nox_modal_info_tickets_team_item_content">
												<span><b v-html="$store.getters.getLanguageText('5.11', 16, { options: [0] })"></b> {{ value.user_id }},</span>
												<span v-if="value.username"><b v-html="$store.getters.getLanguageText('5.11', 16, { options: [1] })"></b> {{ value.username }}</span>
												<span :class="{ green: value.status }" v-html="$store.getters.getLanguageText('5.11', 17, { options: [value.status] })"></span>
											</div>
										</div>
									</div>
									<div class="nox_modal_info_tickets_accordion" v-else>
										<div class="nox_modal_info_tickets_accordion_item" v-for="(value, index) in noxTicketsData" :key="index">
											<div class="nox_modal_info_tickets_accordion_item_head" @click="toggleAccordion(index)">
												<div class="nox_modal_info_tickets_accordion_item_head_title" v-html="$store.getters.getLanguageText('5.11', 8, { number: (index + 1), datetime: (new Date(value.created_at * 1000).toLocaleString($store.state.noxLanguage)), ticket_id: value.ticket_id })"></div>
												<div class="nox_modal_info_tickets_accordion_item_head_comment" v-html="value.comment" v-if="value.comment"></div>
												<div class="nox_modal_info_tickets_accordion_item_head_status" :class="[value.status ? 'green' : 'red']" v-html="$store.getters.getLanguageText('5.11', 14, { options: [value.status] })"></div>
												<font-awesome-icon :icon="['fas', 'angle-up']" v-if="noxIsToggleAccordion && noxToggleAccordionIndex == index" />
												<font-awesome-icon :icon="['fas', 'angle-down']" v-else />
											</div>
											<div class="nox_modal_info_tickets_accordion_item_body" :class="{ hide: (!noxIsToggleAccordion || (noxIsToggleAccordion && noxToggleAccordionIndex !== index)) }">
												<div class="nox_modal_info_tickets_accordion_item_body_qrcode">
													<vue-qrcode :text="$store.state.noxBaseURL + '/t/?' + value.ticket_id" :size="90" color="#000000" bg-color="#FFFFFF" error-level="L"></vue-qrcode>
													<a :href="$store.state.noxMeBaseURL + '/tickets/?' + value.ticket_id" target="_blank"><button type="button" class="nox_button small blue"><font-awesome-icon class="rotate_135" :icon="['fas', 'ticket-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 113)"></span></button></a>
												</div>
												<div class="nox_modal_info_tickets_accordion_item_body_comment">
													<b class="nox_modal_info_tickets_accordion_item_body_comment_label" v-html="$store.getters.getLanguageText('5.11', 9)"></b>
													<div class="nox_modal_info_tickets_accordion_item_body_comment_value"><textarea class="nox_textarea small" v-model.trim="value.comment" :placeholder="$store.getters.getLanguageText('5.11', 10)"></textarea></div>
													<div class="nox_modal_info_tickets_accordion_item_body_comment_alert" v-html="value.temp_alert"></div>
													<div class="nox_modal_info_tickets_accordion_item_body_comment_loading" :class="{ hide: !value.temp_loading }"><img src="@/assets/images/loading.gif"></div>
													<div class="nox_modal_info_tickets_accordion_item_body_comment_buttons" :class="{ hide: value.temp_loading }">
														<button type="button" class="nox_button common red" @click="goToDeleteTicket(index)" v-if="noxSType == 'admin' && $store.state.noxAccountData.group == 1" v-html="$store.getters.getLanguageText('1.1', 121)"></button>
														<button type="button" class="nox_button common" :class="[(($store.state.noxAccountData.group == 1 || $store.state.noxAccountData.id == value.user_id) ? 'green' : 'disabled')]" @click="axios('editTicketComment', index, value.id)" :disabled="!($store.state.noxAccountData.group == 1 || $store.state.noxAccountData.id == value.user_id)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-html="$store.getters.getLanguageText('5.11', (noxSType == 'team' ? 18 : 7))" v-else></div>
							</div>
							<div v-else-if="noxType == 'deleteTicket'" v-html="$store.getters.getLanguageText('5.11', 12, { ticket_id: noxTicketsData[noxIndex].ticket_id })"></div>
							<div v-else-if="noxType == 'deleteTickets'">Вы действительно хотите удалить все билеты мероприятия?</div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="noxType = 'editEvent'" v-if="['deleteEvent', 'deleteTickets'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 55)"></button>
						<button type="button" class="nox_button common" @click="resetAlerts();noxStep -= 1" v-else-if="noxType == 'buyTickets' && noxStep > 1" v-html="$store.getters.getLanguageText('1.1', 55)"></button>
						<button type="button" class="nox_button common" @click="backToGetTickets" v-else-if="noxType == 'deleteTicket'" v-html="$store.getters.getLanguageText('1.1', 55)"></button>
						<button type="button" class="nox_button common" @click="close" v-else v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="button" class="nox_button common green" @click="axios" v-if="noxType == 'addEvent'" v-html="$store.getters.getLanguageText('1.1', 4)"></button>
						<button type="button" class="nox_button common red" @click="noxType = 'deleteTickets'" v-else-if="noxType == 'editEvent' && noxCountBuyTickets" v-html="$store.getters.getLanguageText('1.1', 114)"></button>
						<button type="button" class="nox_button common red" @click="noxType = 'deleteEvent'" v-if="noxType == 'editEvent'" v-html="$store.getters.getLanguageText('1.1', 137)"></button>
						<button type="button" class="nox_button common green" @click="axios" v-if="noxType == 'editEvent'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
						<button type="button" class="nox_button common red" @click="axios" v-else-if="['deleteEvent', 'deleteTicket', 'deleteTickets'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
						<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'buyTickets' && noxStep == 1 && noxIsBuy && noxCountTickets > noxCountBuyTickets" v-html="$store.getters.getLanguageText('1.1', 110)"></button>
						<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'buyTickets' && noxStep == 2 && noxIsBuy && noxCountTickets > noxCountBuyTickets" v-html="$store.getters.getLanguageText('1.1', 66)"></button>
						<button type="button" class="nox_button common green" @click="close();$modal.show('NoxModalBalance', { type: 'addMainBalance' })" v-else-if="noxType == 'buyTickets' && !noxIsBuy && noxCountTickets > noxCountBuyTickets" v-html="$store.getters.getLanguageText('1.1', 100)"></button>
						<button type="button" class="nox_button common blue" @click="axios" v-else-if="noxType == 'getTickets' && noxTicketsData.length && noxTicketsDataLimitOffset" v-html="$store.getters.getLanguageText('1.1', 20)"></button>
						<button type="button" class="nox_button common green" @click="close();$modal.show(($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'NoxModalEvents' : 'NoxModalBalance'), { type: ($store.state.noxAccountData.is_verification && $store.state.noxAccountData.balance_nox >= 0 ? 'buyTickets' : 'addMainBalance'), index: noxIndex, id: noxId })" v-else-if="noxType == 'getTickets' && noxSType !== 'team' && !noxTicketsData.length" v-html="$store.getters.getLanguageText('1.1', 110)"></button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertStatus: '',
			noxAlertUserId: '',
			noxAlertNameRU: '',
			noxAlertNameEN: '',
			noxAlertTextRU: '',
			noxAlertTextEN: '',
			noxAlertBGColor: '',
			noxAlertDatetime: '',
			noxAlertLocationRU: '',
			noxAlertLocationEN: '',
			noxAlertTicketCost: '',
			noxAlertTicketCount: '',
			noxAlertTicketTextRU: '',
			noxAlertTicketTextEN: '',
			noxAlertTicketPassword: '',
			noxAlertCountTickets: '',
			noxStep: 0,
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxSType: '',
			noxBalance: 0,
			noxBalance1: 0,
			noxBalance2: 0,
			noxBalance3: 0,
			noxSystemVat: 0,
			noxTotalCost: 0,
			noxStartCount: 0,
			noxTicketCount: '',
			noxStatus: 0,
			noxUserId: '',
			noxNameRU: '',
			noxNameEN: '',
			noxTextRU: '',
			noxTextEN: '',
			noxBGColor: '',
			noxDatetime: '',
			noxLocationRU: '',
			noxLocationEN: '',
			noxTicketCost: '',
			noxTicketTextRU: '',
			noxTicketTextEN: '',
			noxTicketPassword: '',
			noxTicketsData: [],
			noxTicketsDataLimitOffset: 0,
			noxTicketsDataLimitCount: 50,
			noxToggleAccordionIndex: -1,
			noxCountTickets: '',
			noxCountBuyTickets: '',
			noxIsToggleAccordion: false,
			noxIsOpenLoading: false,
			noxIsLoading: false,
			noxIsError: false,
			noxIsBuy: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxStep = 1;
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxSType = event.params.stype;
				this.noxBalance1 = Number(this.$store.state.noxAccountData.balance_nox);
				this.noxBalance2 = Number(this.$store.state.noxAccountData.balance_nox_zp);
				this.noxBalance3 = Number(this.$store.state.noxAccountData.balance_of_bonuses);
				this.noxBalance = Math.round((this.noxBalance1 + this.noxBalance3) * 100) / 100;
				this.noxSystemVat = 0;
				this.noxTotalCost = 0;
				this.noxStartCount = 0;
				this.noxTicketCount = '';
				this.noxStatus = 0;
				this.noxUserId = '';
				this.noxNameRU = '';
				this.noxNameEN = '';
				this.noxTextRU = '';
				this.noxTextEN = '';
				this.noxBGColor = '';
				this.noxDatetime = '';
				this.noxLocationRU = '';
				this.noxLocationEN = '';
				this.noxTicketCost = '';
				this.noxTicketTextRU = '';
				this.noxTicketTextEN = '';
				this.noxTicketPassword = '';
				this.noxTicketsData = [];
				this.noxTicketsDataLimitOffset = 0;
				this.noxTicketsDataLimitCount = 50;
				this.noxToggleAccordionIndex = -1;
				this.noxCountTickets = '';
				this.noxCountBuyTickets = '';
				this.noxIsToggleAccordion = false;
				this.noxIsOpenLoading = false;
				this.noxIsLoading = false;
				this.noxIsError = false;
				this.noxIsBuy = false;

				if      (this.noxType == 'editEvent') { this.initEvent(); }
				else if (this.noxType == 'getTickets') { this.axios(); }
				else if (this.noxType == 'buyTickets') { this.axios('initEvent'); }
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertStatus = '';
				this.noxAlertUserId = '';
				this.noxAlertNameRU = '';
				this.noxAlertNameEN = '';
				this.noxAlertTextRU = '';
				this.noxAlertTextEN = '';
				this.noxAlertBGColor = '';
				this.noxAlertDatetime = '';
				this.noxAlertLocationRU = '';
				this.noxAlertLocationEN = '';
				this.noxAlertTicketCost = '';
				this.noxAlertTicketCount = '';
				this.noxAlertTicketTextRU = '';
				this.noxAlertTicketTextEN = '';
				this.noxAlertTicketPassword = '';
				this.noxAlertCountTickets = '';
			},
			initEvent: function() {

				this.noxStatus = Number(this.$parent.noxWidget6[this.noxIndex].status);
				this.noxUserId = Number(this.$parent.noxWidget6[this.noxIndex].user_id);
				this.noxNameRU = this.$parent.noxWidget6[this.noxIndex].name_ru;
				this.noxNameEN = this.$parent.noxWidget6[this.noxIndex].name_en;
				this.noxTextRU = this.$parent.noxWidget6[this.noxIndex].text_ru;
				this.noxTextEN = this.$parent.noxWidget6[this.noxIndex].text_en;
				this.noxBGColor = this.$parent.noxWidget6[this.noxIndex].bg_color;
				this.noxDatetime = this.$parent.noxWidget6[this.noxIndex].datetime;
				this.noxLocationRU = this.$parent.noxWidget6[this.noxIndex].location_ru;
				this.noxLocationEN = this.$parent.noxWidget6[this.noxIndex].location_en;
				this.noxTicketCost = Number(this.$parent.noxWidget6[this.noxIndex].ticket_cost);
				this.noxTicketTextRU = this.$parent.noxWidget6[this.noxIndex].ticket_text_ru;
				this.noxTicketTextEN = this.$parent.noxWidget6[this.noxIndex].ticket_text_en;
				this.noxTicketPassword = this.$parent.noxWidget6[this.noxIndex].ticket_password;
				this.noxCountTickets = Number(this.$parent.noxWidget6[this.noxIndex].count_tickets);
				this.noxCountBuyTickets = Number(this.$parent.noxWidget6[this.noxIndex].count_buy_tickets);
			},
			getError: function(i, i1) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 3); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 4); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 5); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 6); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 7); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 8); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 9); }
				else if (i == 11) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 10); }
				else if (i == 12) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 11); }
				else if (i == 13) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 12); }
				else if (i == 14) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 13); }
				else if (i == 15) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 14); }
				else if (i == 16) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 15); }
				else if (i == 17) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 16); }
				else if (i == 18) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 17); }
				else if (i == 19) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 18); }
				else if (i == 20) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 19); }
				else if (i == 21) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 20); }
				else if (i == 22) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 21); }
				else if (i == 23) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 22); }
				else if (i == 24) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 23); }
				else if (i == 25) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 24); }
				else if (i == 26) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 25, { min: 1, max: i1 }); }
				else if (i == 27) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 26); }
				else if (i == 28) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 27); }
				else if (i == 29) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 28); }
				else if (i == 30) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 29); }
				else if (i == 31) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 30); }
				else if (i == 32) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 31); }
				else if (i == 33) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 32); }
				else if (i == 34) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 33); }
				else if (i == 35) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 34); }
				else if (i == 36) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 35); }
				else if (i == 37) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 36); }
				else if (i == 38) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 37); }
				else if (i == 39) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 38); }
				else if (i == 40) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 39); }
				else if (i == 41) { this.noxTemp = this.$store.getters.getLanguageText('1.3.11', 40, { options: [this.$parent.$parent.$parent.getDeclension(i1)], count: i1 }); }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			changeTicketCount: function() {
				this.noxTicketCount = isNaN(this.noxTicketCount) ? '' : this.noxTicketCount;
				this.noxTicketCount = this.noxTicketCount > 100000 ? 100000 : this.noxTicketCount;
				this.noxTicketCount = this.noxTicketCount <= 0 ? '' : this.noxTicketCount;
				this.noxStartCount = Number(this.noxTicketCount);
				this.noxTotalCost = this.noxTicketCost * (1 + this.noxSystemVat / 100);
				this.noxTotalCost = Math.round(this.noxTotalCost * 100) / 100;
				this.noxTotalCost = this.noxTotalCost * this.noxStartCount;
				this.noxIsBuy = this.noxBalance >= this.noxTotalCost ? true : false;
			},
			axios: function(type, var1, var2) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addEvent', 'editEvent'].includes(_this.noxType)) {
						if (_this.noxUserId && !_this.$store.state.noxRegex_d.test(_this.noxUserId)) {
							_this.noxAlertUserId = _this.getError(6);
						}
						if (!_this.noxNameRU) {
							_this.noxAlertNameRU = _this.getError(7);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxNameRU)) {
							_this.noxAlertNameRU = _this.getError(8);
						}
						if (!_this.noxNameEN) {
							_this.noxAlertNameEN = _this.getError(9);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxNameEN)) {
							_this.noxAlertNameEN = _this.getError(10);
						}
						if (!_this.noxTextRU) {
							_this.noxAlertTextRU = _this.getError(11);
						}
						if (!_this.noxTextEN) {
							_this.noxAlertTextEN = _this.getError(13);
						}
						if (!_this.noxDatetime) {
							_this.noxAlertDatetime = _this.getError(15);
						}
						else if ((new Date(_this.noxDatetime)) == 'Invalid Date') {
							_this.noxAlertDatetime = _this.getError(16);
						}
						if (!_this.noxLocationRU) {
							_this.noxAlertLocationRU = _this.getError(17);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxLocationRU)) {
							_this.noxAlertLocationRU = _this.getError(18);
						}
						if (!_this.noxLocationEN) {
							_this.noxAlertLocationEN = _this.getError(19);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxLocationEN)) {
							_this.noxAlertLocationEN = _this.getError(20);
						}
						if (_this.noxBGColor && !_this.$store.state.noxRegexHEXColor.test(_this.noxBGColor)) {
							_this.noxAlertBGColor = _this.getError(22);
						}
						if (!_this.noxTicketPassword) {
							_this.noxAlertTicketPassword = _this.getError(23);
						}
						else if (!_this.$store.state.noxRegex_w.test(_this.noxTicketPassword)) {
							_this.noxAlertTicketPassword = _this.getError(24);
						}
						if (!_this.noxCountTickets) {
							_this.noxAlertCountTickets = _this.getError(25);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxCountTickets) || _this.noxCountTickets < 1 || _this.noxCountTickets > 100000) {
							_this.noxAlertCountTickets = _this.getError(26, 100000);
						}
						if (!_this.noxTicketCost) {
							_this.noxAlertTicketCost = _this.getError(27);
						}
						else if (!_this.$store.state.noxRegexIntOrFloatNumbers.test(_this.noxTicketCost) || _this.noxTicketCost < 0.01) {
							_this.noxAlertTicketCost = _this.getError(28);
						}
						if (!_this.noxIsError) {
							_this.noxIsLoading = true;
							config.url = '/v2/admin/account/events';
							config.data = { id: _this.noxId, status: _this.noxStatus, user_id: Number(_this.noxUserId), name_ru: _this.noxNameRU, name_en: _this.noxNameEN, text_ru: _this.noxTextRU, text_en: _this.noxTextEN, datetime: _this.noxDatetime, location_ru: _this.noxLocationRU, location_en: _this.noxLocationEN, bg_color: _this.noxBGColor, ticket_cost: Number(_this.noxTicketCost), ticket_text_ru: _this.noxTicketTextRU, ticket_text_en: _this.noxTicketTextEN, ticket_password: _this.noxTicketPassword, count_tickets: Number(_this.noxCountTickets) };
							config.method = (_this.noxType == 'addEvent' ? 'post' : 'patch');
						}
					}
					else if (_this.noxType == 'deleteEvent') {
						_this.noxIsLoading = true;
						config.url = '/v2/admin/account/events';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}
					else if (_this.noxType == 'buyTickets') {
						if (type == 'initEvent') {
							_this.noxIsOpenLoading = true;
							config.url = '/v2/account/events/data';
							config.params = { id: _this.noxId };
						}
						else if (_this.noxIsBuy) {
							if (!_this.noxStartCount) {
								_this.noxAlertTicketCount = _this.getError(25);
							}
							else if (!_this.$store.state.noxRegex_d.test(_this.noxStartCount) || _this.noxStartCount < 1 || _this.noxStartCount > _this.noxCountTickets) {
								_this.noxAlertTicketCount = _this.getError(26, _this.noxCountTickets);
							}
							if (!_this.noxIsError) {
								if (_this.noxStep == 1) {
									_this.noxStep = 2; return false;
								}
								else {
									_this.noxIsLoading = true;
									config.url = '/v2/account/events/tickets/buy';
									config.data = { id: _this.noxId, count: _this.noxStartCount };
									config.method = 'post';
								}
							}
						}
						else {
							_this.$parent.$parent.$parent.goToTop(true); _this.$router.push({ path: '/account/balance' }); return false;
						}
					}
					else if (_this.noxType == 'getTickets') {
						if (type == 'editTicketComment') {
							_this.noxTicketsData[var1].temp_alert = '';
							if (_this.noxTicketsData[var1].comment && (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxTicketsData[var1].comment) || _this.noxTicketsData[var1].comment.length > 200)) {
								_this.noxTicketsData[var1].temp_alert = _this.getError(36);
							}
							if (!_this.noxIsError) {
								_this.noxTicketsData[var1].temp_loading = true;
								config.url = '/v2' + (_this.noxSType == 'admin' ? '/admin' : '') + '/account/events/tickets/comment';
								config.data = { id: var2, comment: _this.noxTicketsData[var1].comment };
								config.method = 'patch';
							}
						}
						else {
							_this.noxIsLoading = true;
							_this.noxIsOpenLoading = !_this.noxTicketsDataLimitOffset ? true : false;
							config.url = '/v2' + (_this.noxSType == 'admin' ? '/admin' : '') + '/account/events/tickets';
							config.params = { id: _this.noxId, type: _this.noxSType, limitOffset: _this.noxTicketsDataLimitOffset, limitCount: _this.noxTicketsDataLimitCount };
						}
					}
					else if (_this.noxType == 'deleteTicket') {
						_this.noxIsLoading = true;
						config.url = '/v2/admin/account/events/tickets/' + _this.noxTicketsData[_this.noxIndex].id;
						config.method = 'delete';
					}
					else if (_this.noxType == 'deleteTickets') {
						_this.noxIsLoading = true;
						config.url = '/v2/admin/account/events/tickets';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (['addEvent', 'editEvent', 'deleteEvent', 'deleteTicket', 'deleteTickets'].includes(_this.noxType)) {
									if (_this.noxType == 'addEvent') {
										_this.$parent.noxWidget6.push(data.data);
									}
									else if (_this.noxType == 'editEvent') {
										_this.$parent.noxWidget6.splice(_this.noxIndex, 1, data.data);
									}
									else if (_this.noxType == 'deleteEvent') {
										_this.$parent.noxWidget6.splice(_this.noxIndex, 1);
									}
									else if (_this.noxType == 'deleteTickets') {
										_this.$parent.noxWidget6[_this.noxIndex]['count_buy_tickets'] = 0;
										_this.$parent.noxWidget6[_this.noxIndex]['count_buy_tickets_by_user'] = 0;
									}
									_this.close();
								}
								else if (_this.noxType == 'buyTickets') {
									if (data.data.account_data !== undefined) {
										_this.$parent.$parent.$parent.initAccountData(data.data.account_data);
									}
									if (data.data.event_data !== undefined) {
										_this.$parent.noxWidget6.splice(_this.noxIndex, 1, data.data.event_data);
									}
									if (type == 'initEvent') { _this.initEvent(); _this.changeTicketCount(); } else { _this.close(); }
								}
								else if (_this.noxType == 'getTickets') {
									if (type == 'editTicketComment') {
										// ...
									}
									else {
										if (data.data.data.length) {
											for (var i in data.data.data) {
												data.data.data[i].temp_alert = '';
												data.data.data[i].temp_loading = false;
												_this.noxTicketsData.push(data.data.data[i]);
											}
										}
										_this.noxTicketsDataLimitOffset = data.data.count > _this.noxTicketsDataLimitOffset + _this.noxTicketsDataLimitCount ? _this.noxTicketsDataLimitOffset + _this.noxTicketsDataLimitCount : 0;
									}
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addEvent', 'editEvent', 'deleteEvent', 'buyTickets', 'deleteTickets'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'STATUS_IS_EMPTY') { _this.noxAlertStatus = _this.getError(3); }
										else if (data.response.data.error == 'STATUS_NOT_VALID') { _this.noxAlertStatus = _this.getError(4); }
										else if (data.response.data.error == 'USER_ID_IS_EMPTY') { _this.noxAlertUserId = _this.getError(5); }
										else if (data.response.data.error == 'USER_ID_NOT_VALID') { _this.noxAlertUserId = _this.getError(6); }
										else if (data.response.data.error == 'NAME_RU_IS_EMPTY') { _this.noxAlertNameRU = _this.getError(7); }
										else if (data.response.data.error == 'NAME_RU_NOT_VALID') { _this.noxAlertNameRU = _this.getError(8); }
										else if (data.response.data.error == 'NAME_EN_IS_EMPTY') { _this.noxAlertNameEN = _this.getError(9); }
										else if (data.response.data.error == 'NAME_EN_NOT_VALID') { _this.noxAlertNameEN = _this.getError(10); }
										else if (data.response.data.error == 'TEXT_RU_IS_EMPTY') { _this.noxAlertTextRU = _this.getError(11); }
										else if (data.response.data.error == 'TEXT_RU_NOT_VALID') { _this.noxAlertTextRU = _this.getError(12); }
										else if (data.response.data.error == 'TEXT_EN_IS_EMPTY') { _this.noxAlertTextEN = _this.getError(13); }
										else if (data.response.data.error == 'TEXT_EN_NOT_VALID') { _this.noxAlertTextEN = _this.getError(14); }
										else if (data.response.data.error == 'DATETIME_IS_EMPTY') { _this.noxAlertDatetime = _this.getError(15); }
										else if (data.response.data.error == 'DATETIME_NOT_VALID') { _this.noxAlertDatetime = _this.getError(16); }
										else if (data.response.data.error == 'LOCATION_RU_IS_EMPTY') { _this.noxAlertLocationRU = _this.getError(17); }
										else if (data.response.data.error == 'LOCATION_RU_NOT_VALID') { _this.noxAlertLocationRU = _this.getError(18); }
										else if (data.response.data.error == 'LOCATION_EN_IS_EMPTY') { _this.noxAlertLocationEN = _this.getError(19); }
										else if (data.response.data.error == 'LOCATION_EN_NOT_VALID') { _this.noxAlertLocationEN = _this.getError(20); }
										else if (data.response.data.error == 'BG_COLOR_IS_EMPTY') { _this.noxAlertBGColor = _this.getError(21); }
										else if (data.response.data.error == 'BG_COLOR_NOT_VALID') { _this.noxAlertBGColor = _this.getError(22); }
										else if (data.response.data.error == 'TICKET_PASSWORD_IS_EMPTY') { _this.noxAlertTicketPassword = _this.getError(23); }
										else if (data.response.data.error == 'TICKET_PASSWORD_NOT_VALID') { _this.noxAlertTicketPassword = _this.getError(24); }
										else if (data.response.data.error == 'COUNT_TICKETS_IS_EMPTY') { _this.noxAlertCountTickets = _this.getError(25); }
										else if (data.response.data.error == 'COUNT_TICKETS_NOT_VALID') { _this.noxAlertCountTickets = _this.getError(26, 100000); }
										else if (data.response.data.error == 'TICKET_COST_IS_EMPTY') { _this.noxAlertTicketCost = _this.getError(27); }
										else if (data.response.data.error == 'TICKET_COST_NOT_VALID') { _this.noxAlertTicketCost = _this.getError(28); }
										else if (data.response.data.error == 'TICKET_TEXT_RU_IS_EMPTY') { _this.noxAlertTicketTextRU = _this.getError(29); }
										else if (data.response.data.error == 'TICKET_TEXT_RU_NOT_VALID') { _this.noxAlertTicketTextRU = _this.getError(30); }
										else if (data.response.data.error == 'TICKET_TEXT_EN_IS_EMPTY') { _this.noxAlertTicketTextEN = _this.getError(31); }
										else if (data.response.data.error == 'TICKET_TEXT_EN_NOT_VALID') { _this.noxAlertTicketTextEN = _this.getError(32); }
										else if (data.response.data.error == 'COUNT_IS_EMPTY') { _this.noxAlertTicketCount = _this.getError(25); }
										else if (data.response.data.error == 'COUNT_NOT_VALID') { _this.noxAlertTicketCount = _this.getError(26, _this.noxCountTickets); }
									}
									else if ((_this.noxType == 'getTickets' && type == 'editTicketComment') || _this.noxType == 'deleteTicket') {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxTicketsData[var1].temp_alert = _this.getError(33); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxTicketsData[var1].temp_alert = _this.getError(34); }
										else if (data.response.data.error == 'COMMENT_IS_EMPTY') { _this.noxTicketsData[var1].temp_alert = _this.getError(35); }
										else if (data.response.data.error == 'COMMENT_NOT_VALID') { _this.noxTicketsData[var1].temp_alert = _this.getError(36); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['editEvent', 'deleteEvent', 'buyTickets', 'deleteTickets'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(37); }
									}
									else if ((_this.noxType == 'getTickets' && type == 'editTicketComment') || _this.noxType == 'deleteTicket') {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxTicketsData[var1].temp_alert = _this.getError(38); }
									}
								}
								else if (data.response.status == 409) {
									if (_this.noxType == 'buyTickets') {
										if      (data.response.data.error == 'NOT_ENOUGH_MONEY') { _this.noxAlert = _this.getError(39); }
										else if (data.response.data.error == 'NEGATIVE_BALANCE') { _this.noxAlert = _this.getError(40); }
										else if (data.response.data.error == 'NO_TICKETS_TO_BUY') { _this.noxAlert = _this.getError(41, Number(data.response.data.tickets_left)); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							if (_this.noxType == 'getTickets' && type == 'editTicketComment') {
								_this.noxTicketsData[var1].temp_loading = false;
							}
							_this.noxIsOpenLoading = false;
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChange: function(event, type) {
				if (type == 1) { this.noxStatus = Number(event.value); }
			},
			toggleAccordion: function(index) {
				this.noxIsToggleAccordion = this.noxToggleAccordionIndex == index ? !this.noxIsToggleAccordion : true;
				this.noxToggleAccordionIndex = index;
			},
			goToDeleteTicket: function(index) {
				this.noxType = 'deleteTicket';
				this.noxIndex = index;
			},
			backToGetTickets: function() {
				this.noxType = 'getTickets';
				this.noxIndex = 0;
			},
			close: function() {
				this.$modal.hide('NoxModalEvents');
			}
		}
	}
</script>
